import React from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import querystring from 'querystring';
import Root from './components/Root';
import Home from './components/Home';
import ProductsRoot from './components/ProductsRoot';
import ProductDetail from './components/ProductDetail';
import Promo from './components/Promo';
import Contact from './components/Contact';
import StandardPage from './components/StandardPage';
import Error404 from './components/Error404';

export default function(history, tree) {
	function createElement(Component, props) {
		const user = tree.select('user').get();
		const isAdmin = Boolean(user && user.typeCode === 'super');
		return <Component tree={tree} user={user} isAdmin={isAdmin} cart={tree.select('cart').get()} {...props}/>;
	}

	return (
		<Router history={history} createElement={createElement} stringifyQuery={(query) => querystring.stringify(query)}>
			<Route path={arc.path.base} component={Root}>
				<IndexRoute component={Home}/>
				<Route path='browse'>
					<IndexRoute component={ProductsRoot}/>
					<Route path=':categoryCode' component={ProductsRoot}/>
				</Route>
				<Route path='promo'>
					<IndexRoute component={Promo}/>
					<Route path=':promoCode' component={ProductsRoot}/>
				</Route>
				<Route path='product/:sku'>
					<IndexRoute component={ProductDetail}/>
				</Route>
				<Route path='contact' component={Contact}/>
				<Route path=':pageCode' component={StandardPage}/>
				<Route path='*' component={Error404}/>
			</Route>
		</Router>
	);
}

import React from 'react';
import cart from '../../cart';
import Control from 'react-control';
import CartFlyout from '../CartFlyout';
import Icon from '../Icon';
import svgPerson from '../../svgs/person.svg';
import svgShoppingCart from '../../svgs/shopping-cart.svg';
import Link from '../Link';

export default React.createClass({
	getInitialState() {
		return {
			isLoginOpen: false,
			isFlyoutOpen: false,
		};
	},
	openFlyout() {
		this.setState({isFlyoutOpen: true});
		document.addEventListener('click', this.closeFlyout);
	},
	closeFlyout() {
		this.setState({isFlyoutOpen: false});
		document.removeEventListener('click', this.closeFlyout);
	},
	componentWillUnmount() {
		document.removeEventListener('click', this.closeFlyout);
	},
	userBtn() {
		if (this.props.user == null) return null;

		if (this.props.user && this.props.user.typeCode !== 'guest') {
			return (
				<a className='Header_nav_item' href={arc.path.cart+'account'}>
					<span className='Header_nav_icon'><Icon svg={svgPerson}/></span> Your Account
				</a>
			)
		} else {
			return (
				<a className='Header_nav_item' href={arc.path.cart+'login'}>
					<span className='Header_nav_icon'><Icon svg={svgPerson}/></span> Login
				</a>
			);
		}
	},
	cartBtn() {
		return this.props.cart == null ? null : (
			<Control className={'Header_nav_item'+(this.state.isFlyoutOpen ? ' is-active' : '')} onClick={this.openFlyout}>
				<span className='Header_nav_icon'><Icon svg={svgShoppingCart}/></span> {cart.getCount()}
			</Control>
		);
	},
	render() {
		return (
			<header className='Header'>
				<div className='Header_nav'>
					<a className='Header_nav_item' href='tel:+1-973-373-5500'>1-973-373-5500</a>
					{this.userBtn()}
					{this.cartBtn()}
					<div className='Header_flyout'>
					{
						this.state.isFlyoutOpen ? (
							<CartFlyout cart={this.props.cart}/>
						) : null
					}
					</div>
				</div>
			</header>
		);
	}
});